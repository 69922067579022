
import {
  IonButtons,
  IonContent,
  IonHeader,
  alertController,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonMenuButton,
  IonText,
  IonIcon,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, ref, Ref, watchEffect, } from "vue";
import { useRouter } from "vue-router";
import { useErrorBox } from "@/components/errorBox";
import { addCircleOutline, alertCircleOutline, pencil, filterOutline } from "ionicons/icons";
import { useTicketStore } from "@/store/ticketStore";
import { storeToRefs } from "pinia";
import { OrganizationInfo } from "@/models/Organization";
import { ActionTypes, useStore } from "@/store";
import { useFinancialStore } from "@/store/financialStore";
import { DiscountDto, DiscountGroupDto } from "@/models/Discount";
import { TicketType } from "@/models/TicketType";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { useCalendarStore } from "@/store/calendarStore";
import { DynamicScroller, DynamicScrollerItem} from 'vue-virtual-scroller';

export default defineComponent({
  name: "ListUserCategories",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonMenuButton,
    IonText,
    DynamicScroller, DynamicScrollerItem,
  },
  setup() {
    const router = useRouter();
    const ticketStore = useTicketStore();
    const financialStore = useFinancialStore();
    const calendarStore = useCalendarStore();
    const store = useStore();
    const showAll: Ref<boolean> = ref(false);

    const { ticketTypes } = storeToRefs(ticketStore);
    const organizations: Ref<Array<OrganizationInfo>> = ref([]);
    const discounts: Ref<Array<DiscountDto>> = ref([]);
    const discountGroups: Ref<Array<DiscountGroupDto>> = ref([]);
    const selectedIds: Ref<Array<string>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);

    const {showError} = useErrorBox();
    const formatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 });

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }    

    const init = async () => {
      const l = await loading();
      try {

        organizations.value = await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined);
        discounts.value = await financialStore.getDiscounts();
        discountGroups.value = await financialStore.getDiscountGroups();
        allCategories.value = await calendarStore.getCategories(store.state.organization.id);

        if(!ticketTypes.value || ticketTypes.value.length === 0)
          await ticketStore.loadTicketTypes();

      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    };

   
    watchEffect(async () => {
      if (!store.state.organization.id || store.state.organization.id === "") {
        return;
      }
      await init();
    });

    const showDectivateDialog = async () => {
      const alertElement = await alertController
        .create({
          header: 'Piilotetaanko ' + selectedIds.value.length + ' korttityyppiä?',
          message: 'Korttityypit eivät näy enään kassalla tai asiakkaille.',
          buttons: [
            { text:'Piilota', cssClass:'danger', role:'deactivate' },
            { text:'Peru', cssClass:'secondary', role:'cancel' }],
          });

      await alertElement.present();
      const {role} = await alertElement.onDidDismiss();
      if(role !== "deactivate") return;

      const l = await loading();      
      try {
        for (const id of selectedIds.value) { await ticketStore.deactivateTicketType(id); }
        selectedIds.value = [];
        ticketStore.organisationChanged();
        await ticketStore.loadTicketTypes();
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

    const showActivateDialog = async () => {
      const alert = await alertController
        .create({
          header: 'Aktivoidaako ' + selectedIds.value.length + ' korttityyppiä?',
          message: 'Korttityypit ilmestyvät kassalle.',
          buttons: [
            { text:'Aktivoi', cssClass:'danger', role:'activate' },
            { text:'Peru', cssClass:'secondary', role:'cancel' }],
          });

      await alert.present();
      const {role} = await alert.onDidDismiss();
      if(role !== "activate") return;

      const l = await loading();      
      try {
        for (const id of selectedIds.value) { await ticketStore.activateTicketType(id); }
        selectedIds.value = [];
        ticketStore.organisationChanged();
        await ticketStore.loadTicketTypes();
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

    const allFiltered = computed(() => {
      if(showAll.value)
        return ticketTypes.value;

      return ticketTypes.value.filter(i=>i.isAvailable);
    });

    const getSortedDiscounts = (tt: TicketType) => {
      // map using discounts
      const d = tt.discounts
      .map(i=>discounts.value.find(j=>j.id === i))
      .sort((a,b)=>{
        if(!a && !b) return 0;
        if(!a) return 1;
        if(!b) return -1;
        return a.name.localeCompare(b.name)});

      return d;
    };

    const select = (ticketType: TicketType) => {
      const index = selectedIds.value.findIndex(i=>i === ticketType.id);
      if(index > -1)
        selectedIds.value.splice(index,1);
      else
        selectedIds.value.push(ticketType.id);
    };

    const refresh = async (viewStartIndex:number, viewEndIndex:number, visibleStartIndex:number, visibleEndIndex:number) => {
  // console.log('refresh',viewStartIndex, viewEndIndex, visibleStartIndex, visibleEndIndex);

  // if(visibleEndIndex >= messages.value.length - 1) {
    // await onLazyLoad(visibleEndIndex);
  // }
};

    return {
      router,
      allFiltered, showAll, organizations, discounts, discountGroups, selectedIds, select, allCategories,
      showDectivateDialog, showActivateDialog,
      pencil,filterOutline,addCircleOutline, alertCircleOutline,
      formatter, getSortedDiscounts,refresh,
    };
  },
});
