
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonText,
  loadingController,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { saveOutline, copyOutline, } from "ionicons/icons";
import { useStore,  ActionTypes } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { useTicketStore } from "@/store/ticketStore";
import { OrganizationInfo } from "@/models/Organization";
import { DiscountGroupDto } from "@/models/Discount";
import { VatGroupViewModel } from "@/models/ProductViewModel";
import { FinAccountModel } from "@/models/ShoppingCart";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { useCalendarStore } from "@/store/calendarStore";
import { useI18n } from "vue-i18n";

import moment from 'moment-timezone';
import { stringToHash } from "@/utils/changeTracking";
import { MessageSchema, NumberSchema } from "@/main";
import { AccessProfileDto } from "@/models/AccessProfileDto";
import { EventSeasonInfo } from "@/models/EventSeasonInfo";

type StatusOption = {
  code: string;
  title: string;
}

export default defineComponent({
  name: "TicketTypeDetailsPage",
  data() {
    return {
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonButton,
    IonText,
    IonIcon,
    IonSelect,
    IonSelectOption,
    // IonCheckbox,
    IonDatetimeButton,
    IonDatetime,
    IonModal,
    IonLabel,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const ticketStore = useTicketStore();
    const calendarStore = useCalendarStore();

    const {showError} = useErrorBox();
    const eventSeason: Ref<EventSeasonInfo|undefined> = ref(undefined);
    const organizations: Ref<Array<OrganizationInfo>> = ref([]);
    const discountGroups: Ref<Array<DiscountGroupDto>> = ref([]);
    const vatGroups: Ref<Array<VatGroupViewModel>> = ref([]);
    const accounts: Ref<Array<FinAccountModel>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);
    const allAccessProfiles: Ref<Array<AccessProfileDto>> = ref([]);

    const statusOptions: Ref<Array<StatusOption>> = ref([
      { code: "Draft", title: "Keskeneräinen" },
      { code: "Presale", title: "Ennakkomyynnissä" },
      { code: "Open", title: "Myynnissä" },
      { code: "Closed", title: "Suljettu" },
    ]);

    const typeOptions: Ref<Array<StatusOption>> = ref([
      { code: "Lapset", title: "Lasten kausi" },
      { code: "Jatkokurssi", title: "Jatkokurssi" },
    ]);

    const { t, locale, } = useI18n<{ message: MessageSchema; number: NumberSchema; }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }    


    onMounted(async () => {
      const id = router.currentRoute.value.params["id"].toString();
      const l = await loading();
      try {

        organizations.value = await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined);
        allCategories.value = await calendarStore.getCategories(store.state.organization.id);
        allAccessProfiles.value = await ticketStore.getAccessProfiles(store.state.organization.id);

        if(!id || id === ""|| id === "new"){
          eventSeason.value = new EventSeasonInfo();
          savedHash.value = hashComponentState.value;
          return;
        }

        eventSeason.value = await ticketStore.getEventSeason(id);
        savedHash.value = hashComponentState.value;

      } catch(err){
        showError(err, "virhe");
      } finally{
        await l.dismiss();
      }
    });


  
    const save = async () => {
      if(!eventSeason.value)
        return;

      const l = await loading();
      try {
        eventSeason.value = await ticketStore.updateEventSeason(eventSeason.value);
        savedHash.value = hashComponentState.value;
      } catch(err){
        showError(err, "virhe");
      } finally{
        await l.dismiss();
      }
    }

  

    const organizationsFiltered = computed(() => {
      if(!eventSeason.value)
        return [];

      return organizations.value
        .filter(d => d.isVisible || eventSeason.value!.organizationId === d.id)
        .sort((a,b)=>a.name.localeCompare(b.name));
    });

    const categoriesFiltered = computed(() => {
      if(!eventSeason.value)
        return [];

      return allCategories.value
        .filter(c => c.active || eventSeason.value!.categoryId === c.id)
        .sort((a,b)=>(a.title ?? '').localeCompare(b.title ?? ''));        
    });

    const getForIonDateTime = (date:Date|string|undefined) => {
      return momentjs(date).utc(true).toISOString();
    }

    const setFromIonDateTime = (date:any) => {
      return momentjs(date).toISOString();
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        ticketType: eventSeason.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    onBeforeRouteLeave(() => {
        if(isDirty.value)
          return window.confirm( t('global.unsavedChanges'));
        
        return true;
      });

    const makeCopy = async () => {
      if(!eventSeason.value)
        return;

        eventSeason.value.id = "";
    }

    return {
      save, getForIonDateTime, setFromIonDateTime, t, locale, makeCopy,
      eventSeason, isDirty,
      saveOutline, copyOutline,
      statusOptions,typeOptions,
      organizationsFiltered, discountGroups, vatGroups, accounts, categoriesFiltered,
    };
  }
});
