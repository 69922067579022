

import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonDatetime,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonDatetimeButton,
  IonModal,
  IonCol,
  IonRow,
  loadingController,
  IonSelect, 
  IonSelectOption,
} from "@ionic/vue";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch,
  watchEffect,
} from "vue";
import {
  calendarOutline,
  peopleOutline,
  repeatOutline,
  ellipsisHorizontalOutline,
  star, starOutline,
  trash,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { useStore, ActionTypes, MutationTypes } from "@/store";
import { EventListByLocationDetailed5Result, LocationCalendar, } from "@/models/LocationCalendar";
import { useErrorBox } from "@/components/errorBox";
import { NewReservationRequest } from "@/models/ReservationViewModel";
import { FamilyMemberDetailedViewModel, UserDetailedViewModel, } from "@/models/UserDetailedViewModel";
import * as moment from "moment-timezone";
import { useUserStateUtils } from "@/common/UserStateUtils";
import { emitter } from "@/components/useEvents";
import { EventInstanceUpdated } from "@/models/EventInstanceViewModel";
import { UnsubscribeFunction } from "emittery";
import { CollectionDto } from "@/models/Collections";
import LocationItem from "@/views/LocationItem.vue";
import InstructorItem from "@/views/InstructorItem.vue";
import { useCalendarStore } from "@/store/calendarStore";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "CalendarView",
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    LocationItem,
    InstructorItem,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    // IonLabel,
    IonButton,
    IonDatetime,
    // IonListHeader,
    IonIcon,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonText,
      IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonDatetimeButton,
  IonModal,
  // IonCol,
  // IonRow,
  IonSelect, 
  IonSelectOption,

  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const auth = useAuthStore();
    const calendarStore = useCalendarStore();

    const { showError } = useErrorBox();
    const {verifyAuth} = useUserStateUtils();
    const id = router.currentRoute.value.params["id"].toString();

    const momentjs: any = moment;
    moment.locale("fi");

    const calendar: Ref<LocationCalendar | undefined> = ref(undefined);
    const date: Ref<Date> = ref(new Date());
    const processing: Ref<boolean> = ref(true);
    const processing2: Ref<boolean> = ref(false);

    const showAll: Ref<boolean> = ref(false);

    const nextDate: Ref<Date | undefined> = ref(undefined);

    const collections: Ref<Array<CollectionDto> | undefined> = ref(undefined);
    
    const { calendarCollections } = storeToRefs(calendarStore)

    const organization = computed(() => store.state.organization);

    const customer: Ref<UserDetailedViewModel | undefined> = ref(undefined);
    const familyMember: Ref<FamilyMemberDetailedViewModel | undefined> = ref(undefined);

    const hasRoleInstructor = computed(() => {
      return (
        auth.roles?.indexOf("Instructor") >= 0 ||
        auth.roles?.indexOf("Merchant") >= 0 ||
        auth.roles?.indexOf("Admin") >= 0
      );
    });

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }    


    const loadCalendar = (_firstDate: Date, _organizationId: string) => {
      if(!customer.value || !familyMember.value)
        return;

      processing.value = true;
      calendarStore.getCalendarForMemeber(
          store.state.organization.id,
          undefined,          
          date.value,
          4,
          customer.value.userId,
          familyMember.value.id,
          calendarCollections.value
        )
        .then((response) => {
          if (response) {
            calendar.value = response;
          }
        })
        .catch((err) => {
          showError(err,  "virhe");
        })
        .finally(() => {
          processing.value = false;
          nextDate.value = new Date(momentjs(date.value).add(4, "days").startOf('day'));
        });
    };

    const loadMore = async () => {
      if(!customer.value || !familyMember.value)
        return;

        try {          
          const response = await calendarStore.getCalendarForMemeber(
            store.state.organization.id,
            undefined,          
            nextDate.value,
            1,
            customer.value.userId,
            familyMember.value.id,
            calendarCollections.value
          );

          if (response && response.days)
            calendar.value?.days?.push(...response.days);
          nextDate.value = momentjs(nextDate.value).add(1, "days");
        } catch (err) {
          showError(err as string,  "virhe");
        }
    };

    watchEffect(() => {
      if (!store.state.organization.id || store.state.organization.id === "") {
        return;
      }
      const _id = familyMember.value?.id;
      loadCalendar(date.value, store.state.organization.id);
    });

    watch(
      () => store.state.fullProfile?.userId,
      async (userId, _oldId) => {
        if (id !== "") return;

        if (!userId) {
          customer.value = undefined;
          familyMember.value = undefined;
        } else {
          customer.value = store.getters.fullProfile;
          familyMember.value = store.getters.familyMember;
        }
      }
    );

    let emitterDismiss: UnsubscribeFunction|undefined = undefined;

    onMounted(() => {

      // follow eventInstanceUpdated events
      emitterDismiss = emitter.on('eventInstanceUpdated',async (data: EventInstanceUpdated) => {
        calendar.value?.days?.forEach((day)=>{
          day.events.forEach(e => {
            if(e.instanceID == data.id){
              e.reservations = data.reservations;
              e.visits = data.visits;              
              e.comments = data.comments;
            }
          });
        })
      });

      store.dispatch(ActionTypes.GET_COLLECTIONS, "CALENDAR")
        .then((result) => { collections.value = result; })
        .catch((err) => { showError(err.message,  "virhe"); });
      
      date.value = new Date();

      if (id == "" || !hasRoleInstructor.value) {
        if(store.getters.fullProfile) {
          customer.value = store.getters.fullProfile;
          familyMember.value = store.getters.familyMember;
          } else {
            customer.value = undefined;
            familyMember.value = undefined;
            verifyAuth();
          }
      } else {
        store
          .dispatch(ActionTypes.GET_FULL_PROFILE, parseInt(id))
          .then((result) => {
            customer.value = result;
            familyMember.value = result.familyMembers[0];
          })
          .catch((err) => {
            showError(err.message,  "virhe");
          });
      }
    });

    onUnmounted(async ()=>{
      if(emitterDismiss)
        emitterDismiss();
    });
    
    const selectDate = (message: CustomEvent) => {
      const d = momentjs(message.detail.value);
      date.value = d;
    };

    const registerToEvent = async ( eventInstance: EventListByLocationDetailed5Result ) => {
      if (!eventInstance?.instanceID) {
        showError("Tuntia ei löydy.",  "virhe");
        return;
      }

      const data = new NewReservationRequest();
      data.eventInstanceId = eventInstance.instanceID;
      data.quantity = 1;
      data.userId = customer.value?.userId ?? 0;
      data.familyMemberId = familyMember.value?.id ?? "";
      
      const l = await loading();

      try {
        const r = await store.dispatch(ActionTypes.ADD_RESERVATION, data);

        // Already saved for current user inside store
        if (data.userId !== store.state.fullProfile?.userId){
          const index = customer.value?.reservations.findIndex((i) => i.id === r.id);
          if (index && index > -1) 
            customer.value?.reservations.splice(index, 1);
          customer.value?.reservations.push(r);
        }
      } catch (err) {
        await showError(err as string,  "virhe");
        if (id === ""){
          await store.dispatch(ActionTypes.REFRESH_RESERVATIONS, undefined);
        }        
        loadCalendar(date.value, store.state.organization.id);        
      } finally {

        l.dismiss();      
      }
    };

    const unRegisterEvent = async (eventInstance: EventListByLocationDetailed5Result) => {
      if (!eventInstance?.instanceID) {
        showError("Tuntia ei löydy.",  "virhe");
        return;
      }

      const e = customer.value?.reservations.find((i) => i.eventInstanceId === eventInstance.instanceID);
      if (!e) {
        store.dispatch(ActionTypes.REFRESH_RESERVATIONS, undefined);
        return;
      }

      const l = await loading();

      try {
        await store.dispatch(ActionTypes.REMOVE_RESERVATION, e.id);
        const reservations = customer.value?.reservations;
        if (!reservations) return;

        const index = reservations.findIndex((i) => i.id === e.id);
        if (index > -1) reservations.splice(index, 1);
      } catch (err){
        showError(err as string,  "virhe");
        store.dispatch(ActionTypes.REFRESH_RESERVATIONS, undefined);
      } finally {
        l.dismiss();
      }
    };

    const isRegistered = (eventInstance: EventListByLocationDetailed5Result) => {
      const r = customer.value?.reservations.find( (i) =>
            i.eventInstanceId === eventInstance.instanceID &&
            i.familyMemberId === familyMember.value?.id );
      return r?.quantity ?? 0;
    };

    const canReserveMore = (eventInstance: EventListByLocationDetailed5Result) => {
      const r = customer.value?.reservations.find( (i) =>
            i.eventInstanceId === eventInstance.instanceID &&
            i.familyMemberId === familyMember.value?.id );

      if(!r) return eventInstance.reserveSlots ?? 1;
      return (eventInstance.reserveSlots ?? 1) - r.quantity;
    };

    const timeMinutes = (eventInstance: EventListByLocationDetailed5Result) => {
      return Math.abs(new Date(eventInstance.beginTime).getTime() - new Date(eventInstance.endTime).getTime()) / 36e5 * 60;
    }

    const reserveScreen = (item: EventListByLocationDetailed5Result) => {
      router.push(`/reserve/${item.instanceID}`);
    };

    const showPartisipants = (item: EventListByLocationDetailed5Result) => {
      if (item) {
        router.push(`/partisipants/${item.instanceID}`);
      }
    };

    const refreshNow = async (item: any) => {
      console.log(id);
      if (id === ""){
        await store.dispatch(ActionTypes.REFRESH_RESERVATIONS, undefined);
      }

      loadCalendar(date.value, store.state.organization.id);
      item.target.complete();
    };

    const favorite = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return;
      store.commit(MutationTypes.TOGGLE_FAVORITE, eventInstance.templateID);
    };

    const hide = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return;
      store.commit(MutationTypes.TOGGLE_HIDDEN, eventInstance.templateID);
    };

    const isFavorite = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return true;

      if(store.state.favoriteTypes.indexOf(eventInstance.templateID) >= 0)
        return true;

      return false;
    };

    const isHidden = (eventInstance: EventListByLocationDetailed5Result) => {
      if(!eventInstance.templateID) return true;

      if(store.state.hiddenTypes.indexOf(eventInstance.templateID) >= 0)
        return true;

      return false;
    };

    const canReserve = (eventInstance: EventListByLocationDetailed5Result) => {
      if (!eventInstance.reservationRequired || !familyMember.value)
        return false;

      return new Date(eventInstance.beginTime) > new Date();
    };

    const nextFamilyMember = () => {
      if (!familyMember.value || !customer.value) return;

      const updateFamilyMember =
        store.state.familyMember?.id &&
        familyMember.value.id == store.state.familyMember.id;

      let index = customer.value.familyMembers.findIndex(
        (i) => i.id == familyMember.value?.id
      );
      index++;
      if (index == customer.value.familyMembers.length || index < 0) index = 0;

      familyMember.value = customer.value.familyMembers[index];

      if (updateFamilyMember) store.state.familyMember = familyMember.value;
    };

    const multiMembers = computed(() => {
      return !!familyMember.value && !!customer.value && customer.value.familyMembers.length > 1;
    });

    const loadData = async (ev: any) => {
      await loadMore();
      ev.target.complete();
    };

    return {
      router,timeMinutes,
      momentjs,
      calendar,
      organization,
      selectDate,
      calendarOutline,
      repeatOutline,
      ellipsisHorizontalOutline,
      date,
      registerToEvent,
      unRegisterEvent,
      isRegistered, canReserveMore,
      canReserve,
      reserveScreen,
      hasRoleInstructor,
      showPartisipants,
      processing,
      processing2,
      refreshNow,
      peopleOutline,
      familyMember,
      nextFamilyMember,
      multiMembers,
      loadData,
      favorite, hide, isHidden, isFavorite, showAll,
      star, trash,starOutline,
      collections,calendarCollections,
    };
  },
});
