import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/admin/eventSeasons" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('seasonDetails.title')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (_ctx.eventSeason)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        disabled: !_ctx.isDirty,
                        color: "primary",
                        size: "default",
                        onClick: _ctx.save,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(" " + _toDisplayString(_ctx.t('saveButton')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.eventSeason)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.eventSeason.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.eventSeason.name) = $event)),
                    label: _ctx.t('seasonDetails.name'),
                    labelPlacement: "stacked"
                  }, null, 8, ["modelValue", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.eventSeason.categoryId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.eventSeason.categoryId) = $event)),
                    placeholder: _ctx.t('seasonDetails.selectCategory'),
                    multiple: false,
                    label: _ctx.t('seasonDetails.category'),
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoriesFiltered, (cat) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: cat.id,
                          value: cat.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(cat.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.eventSeason.organizationId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.eventSeason.organizationId) = $event)),
                    placeholder: _ctx.t('seasonDetails.selectOrganization'),
                    multiple: false,
                    label: _ctx.t('seasonDetails.organization'),
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationsFiltered, (org) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: org.id,
                          value: org.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(org.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.eventSeason.status,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.eventSeason.status) = $event)),
                    placeholder: _ctx.t('seasonDetails.selectStatus'),
                    multiple: false,
                    label: _ctx.t('seasonDetails.status'),
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions, (org) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: org.code,
                          value: org.code
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(org.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.eventSeason.type,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.eventSeason.type) = $event)),
                    placeholder: _ctx.t('seasonDetails.selectType'),
                    multiple: false,
                    label: _ctx.t('seasonDetails.type'),
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeOptions, (org) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: org.code,
                          value: org.code
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(org.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('seasonDetails.startDate')), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.eventSeason.beginTime)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.eventSeason.beginTime = undefined))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('removeButton')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.eventSeason.beginTime)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.eventSeason.beginTime = new Date()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('setButton')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.eventSeason.beginTime)
                    ? (_openBlock(), _createBlock(_component_ion_datetime_button, {
                        key: 2,
                        datetime: "validityBegins"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  (_ctx.eventSeason.beginTime)
                    ? (_openBlock(), _createBlock(_component_ion_datetime, {
                        key: 0,
                        id: "validityBegins",
                        presentation: "date",
                        locale: _ctx.locale,
                        "prefer-wheel": false,
                        value: _ctx.getForIonDateTime(_ctx.eventSeason.beginTime),
                        onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.eventSeason.beginTime = _ctx.setFromIonDateTime($event.detail.value))),
                        max: "2030"
                      }, null, 8, ["locale", "value"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('seasonDetails.endDate')), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.eventSeason.endTime)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.eventSeason.endTime = undefined))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('removeButton')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.eventSeason.endTime)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.eventSeason.endTime = new Date()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('setButton')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.eventSeason.endTime)
                    ? (_openBlock(), _createBlock(_component_ion_datetime_button, {
                        key: 2,
                        datetime: "validityEnds"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  (_ctx.eventSeason.endTime)
                    ? (_openBlock(), _createBlock(_component_ion_datetime, {
                        key: 0,
                        id: "validityEnds",
                        presentation: "date",
                        locale: _ctx.locale,
                        "prefer-wheel": false,
                        value: _ctx.getForIonDateTime(_ctx.eventSeason.endTime),
                        onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.eventSeason.endTime = _ctx.setFromIonDateTime($event.detail.value))),
                        max: "2030"
                      }, null, 8, ["locale", "value"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _ctx.makeCopy,
                disabled: !_ctx.eventSeason.id,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.copyOutline }, null, 8, ["icon"]),
                  _createTextVNode("  " + _toDisplayString(_ctx.t('copyButton')), 1)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}