
import {
  IonTitle,
  IonItem,
  IonCol,
  IonInput,
  alertController,
  IonIcon,
  IonButton,
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import { calendarOutline, printOutline, sendOutline, trashOutline, } from 'ionicons/icons';
import { useRouter } from "vue-router";
import * as moment from 'moment-timezone';
import { useFinancialStore } from "@/store/financialStore";
import { EditShoppingCartViewModel, ShoppingCartLineViewModel } from "@/models/ShoppingCart";
import { UserPaymentViewModel } from "@/models/UserDetailedViewModel";

export default defineComponent({
  name: "ShoppingCartView",
  components: {
    IonTitle,
    IonItem,
    IonIcon,
    IonButton,
    IonCol,
    IonInput,
  },
  props: {
    cart: { type: Object as PropType<EditShoppingCartViewModel>, required: true },
    readOnly: { type: Boolean, default :true },
  },
  setup(props, {emit}) {
    const router = useRouter();
    const financial = useFinancialStore();
    const momentjs: any = moment;

    const presentRemoveLine = async (currentLine: ShoppingCartLineViewModel) => {
      const alert = await alertController.create({
        header: 'Poistetaanko?',
        message: 'Poistetaanko rivi ja kortti pysyvästi?',
        buttons: [{text: 'Peru', role: 'cancel'},
                  {text: 'Poista', handler: async () => {
                    await financial.removeShoppingCartLine(currentLine.id ?? "", true);
                    if(!props.cart?.id || props.readOnly) return;
                    emit('update');
                  }}]
      });
      await alert.present();
    };

    const presentRemovePayment = async (currentPayment: UserPaymentViewModel) => {
      const alert = await alertController.create({
        header: 'Poistetaanko?',
        message: 'Poistetaanko maksu pysyvästi?',
        buttons: [{text: 'Peru', role: 'cancel'},
                  {text: 'Poista', handler: async () => {
                    if(!props.cart?.id) return;
                    await financial.removePayment(currentPayment?.id ?? "", true);
                    emit('update');
                  }}]
      });
      await alert.present();
    };
    
    const openTicketDetails = (id: string) => {
      router.push("/admin/ticket/" + id);
    };

    return {
      router,
      calendarOutline,printOutline,sendOutline,trashOutline,
      presentRemoveLine, presentRemovePayment,
      openTicketDetails,momentjs,
    };
  },
});
