
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonList,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonLabel,
  IonText,
  IonDatetime,
  IonTextarea,
  IonCheckbox,
  loadingController,
  IonSelect,
  IonSelectOption,
  IonDatetimeButton,  
} from "@ionic/vue";
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, triggerRef, watch  } from "vue";
import { useRouter } from "vue-router";
import { mailOutline, bookmarkOutline, cashOutline,trashOutline, locationOutline, logInOutline, checkmarkOutline, journalOutline, personOutline, personAddOutline, arrowForwardOutline, saveOutline } from "ionicons/icons";
import CardEdit from '@/components/CardEdit.vue'
import { useStore, ActionTypes } from "@/store";
import { FamilyMemberDetailedViewModel, ShoppingCartSimpleViewModel, UserCategoryInfo, UserDetailedViewModel } from "@/models/UserDetailedViewModel";
import { TicketInfo } from "@/models/Ticket";
import { useErrorBox } from "@/components/errorBox";
import { useToast } from "@/components/toastBox";
import { createGuid } from "@/common/utils";
import TicketButton from "@/components/TicketButton.vue";
import * as moment from 'moment-timezone';
import AddDraggable from "@/components/AddDraggable.vue";
import AddDroppable from "@/components/AddDroppable.vue";
import { emitter } from "@/components/useEvents";
import { UnsubscribeFunction } from "emittery";
import { HistoryItem, useUserStore } from "@/store/userStore";
import { useFinancialStore } from "@/store/financialStore";
import { useAuthStore } from "@/store/authStore";
import { OrganizationInfo } from "@/models/Organization";
import ShoppingCartView from "@/views/financial/ShoppingCartView.vue";
import { EditShoppingCartViewModel } from "@/models/ShoppingCart";

export default defineComponent({
  name: "CustomerView",

  data() {
    return {
      processing: false,
      isOpen: false,
      naUser: "",
      naEmail: "",
      mtUser: "",
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonList,
    IonIcon,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    // IonMenu,
    CardEdit,
    IonModal,
    IonLabel,
    IonText,
    IonDatetime,
    IonTextarea,
    IonCheckbox,
    TicketButton,
    IonSelect,
    IonSelectOption,
    IonDatetimeButton,

    AddDraggable,
    AddDroppable,
    ShoppingCartView,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const userStore = useUserStore();
    const auth = useAuthStore();
    const financialStore = useFinancialStore();
    const momentjs: any = moment;
    const {showError} = useErrorBox();
    const {showToast} = useToast();
    const customer: Ref<UserDetailedViewModel|undefined> = ref(undefined);
    const currentMember: Ref<FamilyMemberDetailedViewModel|undefined> = ref(undefined);
    const currentTicket: Ref<TicketInfo|undefined> = ref(undefined);

    const invoiceDetailsVisible = ref(false);
    const cartPreview = ref<EditShoppingCartViewModel|undefined>(undefined);

    const allOrganizations: Ref<Array<OrganizationInfo>> = ref([]);

    const history: Ref<Array<ShoppingCartSimpleViewModel>> = ref([]);
    const userCategories: Ref<Array<UserCategoryInfo>> = ref([]);
    const activeOnly: Ref<boolean> = ref(true);  
    const hasRoleMerchant = computed(() => { return auth.roles?.indexOf("Merchant") >= 0;});
    const hasRoleAdmin = computed(() => { return auth.roles?.indexOf("Admin") >= 0;});

    const userHistory = ref<Array<HistoryItem>>([]);
    const userHistoryVisible = ref(false);
      
    const loading = async () => {
      const loading = await loadingController.create({message: 'Hetki...', duration: undefined});
      await loading.present();
      return loading;
    }    

    const loadProfile = async (profileId: number) => {
      if(!hasRoleMerchant.value){
        router.replace('/');
        return;
      }

      const l = await loading();
      try {

        if(!userCategories.value || userCategories.value.length === 0){
          userCategories.value = await store.dispatch(ActionTypes.GET_USER_CATEGORIES, undefined);
        }

        if(!allOrganizations.value || allOrganizations.value.length === 0){
          allOrganizations.value = (await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined)).filter(i=>i.isVisible);
        }

        const profine = await store.dispatch(ActionTypes.GET_FULL_PROFILE, profileId);
        if(profine?.userId){
          customer.value = profine; 
          }

        history.value = await store.dispatch(ActionTypes.GET_CART_HISTORY, profileId);

      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

   let emitterDismiss: UnsubscribeFunction|undefined = undefined;
   let emitterDismiss2: UnsubscribeFunction|undefined = undefined;


    onMounted(async () => {
       const id = Number.parseInt(router.currentRoute.value.params["id"].toString());

       if(id<=0){
         customer.value = new UserDetailedViewModel();
         store.dispatch(ActionTypes.GET_USER_CATEGORIES, undefined)
          .then((result) => { userCategories.value = result; } )
          .catch((err) => { showError(err, "virhe"); });
         return;
       }

      await loadProfile(id);

      emitterDismiss = emitter.on('updateMemberError',async (data: string) => {
        showError( data +  " kulkuoikeuksien päivittäminen epäonnistui!",  "virhe"); 
      });
      emitterDismiss2 = emitter.on('updateMemberDone',async (data: string) => {
        showToast("",  data +  " kulkuoikeuksien päivittäminen onnistui!");
      });

    });

    onUnmounted(async ()=>{
      if(emitterDismiss)
        emitterDismiss();
      if(emitterDismiss2)
        emitterDismiss2();
    });


    const save = async () => {
      if(!customer.value)
        return false;

      const l = await loading();

      try {
        const r = await store.dispatch(ActionTypes.UPDATE_FULL_PROFILE, customer.value);
        if(r?.userId) { customer.value = r; }
         l.dismiss();
        return true;
      } catch(err) {
        //customer.value = oldValue; 
        const a = await showError(err as string, "virhe");
        l.dismiss();
        await a.onDidDismiss();        
        return false;
      }
    };

    const addMember = () => {
      const member = new FamilyMemberDetailedViewModel();
      customer.value?.familyMembers.push(member)
      };

     const isOpenRef = ref(false);

    const setOpen = (state: boolean,ticket: TicketInfo|undefined) => {
      currentTicket.value = ticket;
      isOpenRef.value = state;}

    const newTicket = () => {
      const ticket = new TicketInfo();
      ticket.memberId = currentMember.value?.id;
      ticket.boughtFrom = "kassa";
      currentTicket.value = ticket;
      currentMember.value = undefined;
      isOpenRef.value = true;
      };

    const saveTicket = async (ticket: Ref<TicketInfo>) => {
      if(!ticket.value.id){
        ticket.value.id = createGuid();
        ticket.value.issued = new Date();

        // New ticket
        const fm = customer.value?.familyMembers.find(i=>i.id === ticket.value.memberId);
          if(!fm) return;

          fm.tickets.push(ticket.value);

          currentTicket.value = ticket.value;
          isOpenRef.value  = false;

          const result = await save();

          if(!result){
            fm.tickets.pop();
            ticket.value.id = "";
            ticket.value.issued = undefined;
            setOpen(true,ticket.value);
          }
          return;
      }

      const l = await loading();
      store.dispatch(ActionTypes.UPDATE_TICKET,ticket.value)
        .then((result) => {
          currentTicket.value = result;
          isOpenRef.value  = false;

          const fm = customer.value?.familyMembers.find(i=>i.id === result.memberId);
          if(!fm) return;
          const index = fm?.tickets.findIndex((i)=>i.id === result.id);
          if((index ?? -1) > -1){
            fm?.tickets.splice(index ?? -1, 1, result); 
          }})
        .catch(err => { showError(err, "virhe"); })
        .finally(()=>{ l.dismiss(); });
      }

    const generateEmptyCart = async () => {
      const fm = customer.value?.userId;
      if(!fm) return;

      const l = await loading();
      store.dispatch(ActionTypes.GENERATE_CART,fm)
        .then((result) => { 
          customer.value?.openShoppingCarts.push(result);
          if(result && result.id){router.replace('/cashRegister/' + result.id);}         
          })
        .catch(err => { showError(err, "virhe"); })
        .finally(()=>{ l.dismiss(); });
      }


    const generateInvoice = async () => {
      const fm = customer.value?.userId;
      if(!fm) return;

      const l = await loading();
      store.dispatch(ActionTypes.GENERATE_INVOICES,fm)
        .then((result) => { customer.value?.openShoppingCarts.push(result);showError('Lisätty.',"info");})
        .catch(err => { showError(err, "virhe"); })
        .finally(()=>{ l.dismiss(); });
      }

    const generatePreviewCart = async () => {
      const l = await loading();
      try {
        debugger;
        cartPreview.value = await financialStore.generateInvoice(customer.value?.userId ?? -1, true)
        invoiceDetailsVisible.value = true;
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

    watch(activeOnly, (_activeOnly, _preActiveOnly) => {
      triggerRef(customer);
    })

    watch(router.currentRoute, async (newRoute, oldRoute) => {
//      console.log(oldRoute);
//      console.log(newRoute);
     
      const newIdString = newRoute.params["id"]?.toString();
      if(newRoute.name === 'Customer' && newIdString && oldRoute.params["id"]?.toString() != newIdString){
//        debugger;
        await loadProfile(Number.parseInt(newIdString));
      }
    })

      const activeTickets = (items: TicketInfo[]) => {
            return items.filter(i=>i.active || i.reserved).length;
            };
      const allTickets = (items: TicketInfo[]) => {
            return items.length;
            };

      const orderedTickets = (items: TicketInfo[]) => {
            return items
            .filter(i=>i.active || i.reserved || !activeOnly.value)
            .sort((a: TicketInfo,b: TicketInfo)=> (a.from?.valueOf()??0) - (b.from?.valueOf()??0));
            };     
        
    const selectDate = (fm: FamilyMemberDetailedViewModel, e: CustomEvent<any>)=> {
      fm.birthDay = e.detail.value;
    };



    const combineSelected = () => {
      const selectedCarts = customer.value?.openShoppingCarts.filter(a=>a.selected);

      if(selectedCarts && selectedCarts.length == 2 && customer.value){
        const ids = {source: selectedCarts[0].id, target: selectedCarts[1].id};
        store.dispatch(ActionTypes.MERGE_SHOPPING_CART, ids)
            .then((result) => {
          if(result?.id && customer.value){
            const index = customer.value.openShoppingCarts.findIndex(i=>i.id == ids.source);
            if (index > -1) {
              customer.value.openShoppingCarts.splice(index, 1);
            }
            const index2 = customer.value.openShoppingCarts.findIndex(i=>i.id == ids.target);
            if (index2 > -1) {
              customer.value.openShoppingCarts.splice(index2, 1,result);
            }
            }
        })
        .catch((err) => {
          showError(err, "virhe");
      });
      }
    }

    const paySelected = () => {
      const selectedCarts = customer.value?.openShoppingCarts.filter(a=>a.selected);
      if(selectedCarts && selectedCarts.length == 1){
        router.replace('/cashRegister/' + selectedCarts[0].id)
      }
    }


    const approvePayment = async () => {
      const l = await loading();
      try {
        const selectedCarts = customer.value?.openShoppingCarts.filter(a=>a.selected);
        if(selectedCarts && selectedCarts.length == 1){
          await financialStore.approveShoppingCartPayment(selectedCarts[0].id);
          if(customer.value?.userId) await loadProfile(customer.value.userId);
        }
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

    const emailSelected = async () => {
      const l = await loading();
      try {
        const selectedCarts = customer.value?.openShoppingCarts.filter(a=>a.selected);
        if(selectedCarts && selectedCarts.length == 1){
          await store.dispatch(ActionTypes.EMAIL_SHOPPING_CART, selectedCarts[0].id);
          if(customer.value?.userId) await loadProfile(customer.value.userId);
        }
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

  const removeSelected = async () => {
    const selectedCarts = customer.value?.openShoppingCarts.filter(a=>a.selected);
    if(!selectedCarts || selectedCarts.length != 1)
      return;

    // if(selectedCarts[0].state != "PENDING_INVOICE"){
    //   showError("Lasku ei avoinna.",true);
    //   }

    const l = await loading();
    try
    {
        await financialStore.removeShoppingCart(selectedCarts[0].id);
        if(customer.value?.userId) await loadProfile(customer.value.userId);
    } catch(err){
      showError(err as string, "virhe");
    } finally{
      l.dismiss();
    }      
  }

  const removeFamilyMember = async () => {
    if(!currentMember.value)
        return;
    const l = await loading();
    try
    {
      await store.dispatch(ActionTypes.REMOVE_FAMILY_MEMBER, {userId:customer.value?.userId ?? -1,familyMemberId:currentMember.value.id});
      currentMember.value = undefined; 
      if(customer.value?.userId)
        await loadProfile(customer.value.userId); 
    } catch(err){
      showError(err as string, "virhe");
    } finally{
      l.dismiss();
    }
  }

    const newUser = (username: string, email: string) => {
      if(!currentMember.value || !username || username === "")
        return;

      const userToCreate = new UserDetailedViewModel();
      userToCreate.userName = username;
      userToCreate.email = email;
      userToCreate.firstName = currentMember.value.firstName;
      userToCreate.lastName = currentMember.value.lastName;
      userToCreate.city = customer.value?.city;
      userToCreate.street = customer.value?.street;
      userToCreate.country = customer.value?.country;
      userToCreate.postCode = customer.value?.postCode;
      userToCreate.phone = customer.value?.phone;
      userToCreate.familyMembers.push(currentMember.value);

      store.dispatch(ActionTypes.UPDATE_FULL_PROFILE, userToCreate)
        .then((result) => { 
          if(result.userId){
            showError("Luotiin asiakasnumerolla " + result.userId,"info");
            triggerRef(customer);
          }})
        .catch((err) => { showError(err, "virhe"); });
    }

    const addToUser = async (username: string) => {
      try {
      const result = await store.dispatch(ActionTypes.SEARCH_USERS,{search: username, categoryId: undefined});
      if(!result || !result.success){
        showError("ei löytynyt.", "info");    
        return;       
      }
      
      let user = undefined;
      const userIdSearch = Number.parseInt(username);
      if (Number.isNaN(userIdSearch)) {
         user = result.items.find(i=>i.userName === username);
      } else {
         user = result.items.find(i=>i.userId === userIdSearch);
      }

      if(!user || !user.userId){
        showError("ei löytynyt.", "info");    
        return;   
      }

      // Get and update full profile
      const fullProfile = await store.dispatch(ActionTypes.GET_FULL_PROFILE, user.userId);

      if(!currentMember.value)
        return;
        
      fullProfile.familyMembers.push(currentMember.value);
      const fp2 = await store.dispatch(ActionTypes.UPDATE_FULL_PROFILE, fullProfile);

      if(!fp2.userId)
        return;

      showError("Lisättiin asiakasnumerolle " + fp2.userId, "virhe");
      triggerRef(customer);

      } catch (err) {
        showError(err as string, "virhe");
      }
    }

    const checkSelectedCategory = (tag1: UserCategoryInfo, tag2: UserCategoryInfo) => {	
      // check if the secondary tag is an array of multiple
			if(Array.isArray(tag2))
				return (tag2 as Array<UserCategoryInfo>).findIndex(i=>tag1.id === i.id)>-1;

      // compare individual objects
			return tag1.id===tag2.id;
		};

    const checkSelectedOrganisation = (tag1: OrganizationInfo, tag2: OrganizationInfo) => {	
      // check if the secondary tag is an array of multiple
			if(Array.isArray(tag2))
				return (tag2 as Array<OrganizationInfo>).findIndex(i=>tag1.id === i.id)>-1;

      // compare individual objects
			return tag1.id===tag2.id;
		};

    const elementDropped = async (value: {dropData: any, destinationData: any}) => {
      // debugger;
        const ticketId: string = value.dropData.ticketId;
        const fromFamilyMemberId: string = value.dropData.familyMemberId;
        const toFamilyMemberId: string = value.destinationData.familyMemberId;

        if(ticketId && fromFamilyMemberId && toFamilyMemberId && fromFamilyMemberId !== toFamilyMemberId){
          const fmFrom = customer.value?.familyMembers.find(i=>i.id === fromFamilyMemberId);
          const fmTo = customer.value?.familyMembers.find(i=>i.id === toFamilyMemberId);

          if(!fmFrom || !fmTo) return;
          const index = fmFrom.tickets.findIndex((i)=>i.id === ticketId);

          if((index ?? -1) < 0) return;
            const ticket = fmFrom.tickets.splice(index ?? -1, 1)[0];
            fmTo.tickets.push(ticket);
        }

      console.log(value);
    };

    const showUserHistory = async () => {
      if(!customer.value?.userId)
        return;

      const l = await loading();
      try {
        userHistoryVisible.value = true;
        userHistory.value = await userStore.getHistoryItems(customer.value.userId);
      } catch (err){
        showError(err as string, "virhe"); 
        userHistoryVisible.value = false;
      } finally {
        l.dismiss();
      }
    }

    return {
      elementDropped,
      history,momentjs,
      generateInvoice, invoiceDetailsVisible, cartPreview, generatePreviewCart,
      router,
      customer,activeTickets,allTickets,
      bookmarkOutline,personOutline, personAddOutline, arrowForwardOutline,checkmarkOutline,logInOutline,locationOutline,cashOutline,trashOutline,
      mailOutline,
      addMember,addToUser,newUser,removeFamilyMember, approvePayment,
      currentMember, hasRoleAdmin,
      userCategories,checkSelectedCategory, checkSelectedOrganisation,
      combineSelected,paySelected,removeSelected,emailSelected,generateEmptyCart,
      selectDate,save,activeOnly,
      setOpen,newTicket,isOpenRef,currentTicket,saveTicket, orderedTickets,saveOutline,journalOutline,
      userHistory,userHistoryVisible,showUserHistory, allOrganizations,
    };
  }
});
