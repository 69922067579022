
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonButton,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInput,
  IonCheckbox,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "../../store";
import { ProductViewModel } from "@/models/ProductViewModel";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { useErrorBox } from "@/components/errorBox";
import { OrganizationInfo } from "@/models/Organization";
import { useCalendarStore } from "@/store/calendarStore";
import { useFinancialStore } from "@/store/financialStore";

export default defineComponent({
  name: "ListProducts",
  data() {
    return {
      searchTerm: "",
      processing: false,
    };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonButton,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonInput,
    IonCheckbox,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const financialStore = useFinancialStore();
    const calendarStore = useCalendarStore();
    const { showError } = useErrorBox();

    const allProducts: Ref<Array<ProductViewModel>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);
    const allOrganizations: Ref<Array<OrganizationInfo>> = ref([]);

    const showAll: Ref<boolean> = ref(false);
    const filterTxt: Ref<string | undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }   

    onMounted(async () => {
      const l = await loading();
      try {
        allCategories.value = (await calendarStore.getCategories(store.state.organization.id));
        allOrganizations.value = (await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined));
        allProducts.value = (await financialStore.getProducts()).items;
      } catch(err){
        showError(err, "virhe");
      } finally {
        l.dismiss();
      }
    });

    const filteredProducts = computed(() => {
      const lowerSearch = filterTxt.value?.toLowerCase();
      return allProducts.value.filter(s => (showAll.value || s.isActive) && (
        !lowerSearch || lowerSearch.length == 0 ||
        s.name?.toLowerCase().indexOf(lowerSearch) >= 0 ||
        s.barcode?.toLowerCase().indexOf(lowerSearch) >= 0 ||
        s.code?.toLowerCase().indexOf(lowerSearch) >= 0))
        .sort((a: any, b: any) => a.orderKey - b.orderKey);
    });

    const editProduct = (item: ProductViewModel) => {
      if (item) {
        router.push(`/editProduct/${item.id}`);
      }
    };

    const getCategory = (id: string) => {
      return allCategories.value.find(i => i.id === id)?.title ?? "";
    };

    const getOrganization = (id: string) => {
      return allOrganizations.value.find(i => i.id === id)?.name ?? "";
    };

    return {
      editProduct,
      filteredProducts,
      getCategory,
      getOrganization,
      allProducts,
      filterTxt,
      showAll,
    };
  },
});
