
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  loadingController,
  IonInput,
  toastController,
alertController,
IonBackButton,
IonIcon,
IonButton,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, ref, } from "vue";
import { calendarOutline, printOutline, sendOutline, trashOutline, } from 'ionicons/icons';
import { useRouter } from "vue-router";
import { useErrorBox } from '@/components/errorBox';
import * as moment from 'moment-timezone';
import { useFinancialStore } from "@/store/financialStore";
import { EditShoppingCartViewModel } from "@/models/ShoppingCart";
import { useAuthStore } from "@/store/authStore";

import ShoppingCartView from "@/views/financial/ShoppingCartView.vue";

export default defineComponent({
  name: "ShoppingCartDetailsPage",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonIcon,
    IonButton,
    IonMenuButton,
    IonInput,
    ShoppingCartView,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    const financial = useFinancialStore();
    const {showError} = useErrorBox();
    const momentjs: any = moment;

    const cart = ref<EditShoppingCartViewModel|undefined>(undefined);
    const email = ref<string>("");

    const hasRoleAdmin = computed(() => auth.roles?.indexOf("Admin") >= 0);

    const hasRoleInstructor = computed(() => { 
      return auth.roles?.indexOf("Accounting") >= 0 ||
        auth.roles?.indexOf("Merchant") >= 0 ||
        auth.roles?.indexOf("Admin") >= 0;});

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }

    const loadCart = async (id: string) => {
      const l = await loading();
      try {
        cart.value = await financial.getShoppingCart(id);

      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    };

    
    onMounted(() => {
      const id = router.currentRoute.value.params["id"].toString();
      loadCart(id);
    });
   
   
    const printReceipt = async () => {
      if(!cart.value?.id)
        return;
      const l = await loading();
      try{
       await financial.printReceipt(cart.value.id);
       const toast = await toastController.create({
          message: "Tulostettu",
          duration: 1500,
          position: "top",
          color: "light",
        });
        await toast.present();
      } catch (err){
        showError(err as string, "virhe");
      } finally {
        l.dismiss();
      }
    };

    const sendEmail = async () => {
      if(!cart.value?.id)
        return;
      const l = await loading();
      try{
        if(!email.value || email.value.length < 5){
          showError("Sähköposti tarvitaan", "virhe");
          return;
        }
        await financial.emailReceipt(cart.value.id, email.value);
        const toast = await toastController.create({
          message: "Lähetetty",
          duration: 1500,
          position: "top",
          color: "light",
        });
        await toast.present();

      } catch (err){
        showError(err as string, "virhe");
      } finally {
        l.dismiss();
      }

    };

    
    const presentRemoveReceipt = async () => {
      if(!cart.value) return;
      const alert = await alertController.create({
        header: 'Poistetaanko?',
        message: 'Poistetaanko ostos & kuitti pysyvästi?',
        buttons: [{text: 'Peru', role: 'cancel'},
                  {text: 'Poista', handler: async () => {
                    await financial.removeShoppingCart(cart.value?.id ?? "", true);
                    router.back();
                  }}]
      });
      await alert.present();
    };

    const openTicketDetails = (id: string) => {
      router.push("/admin/ticket/" + id);
    };

    const reload = async () => {
      if(!cart.value?.id) return;
      await loadCart(cart.value.id);
    };

    return {
      router,
      calendarOutline,printOutline,sendOutline,trashOutline,
      hasRoleInstructor,
      momentjs,
      printReceipt,sendEmail,
      email,
      presentRemoveReceipt,
      cart,
      hasRoleAdmin, openTicketDetails, reload,
    };
  },
});
