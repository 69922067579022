
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonMenuButton,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "../store";
import { EventDescription } from "@/models/EventDescription";
import { OrganizationInfo } from "@/models/Organization";
import * as moment from 'moment-timezone';
import { useErrorBox } from "@/components/errorBox";
import { useCalendarStore } from "@/store/calendarStore";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";

export default defineComponent({
  name: "ListEvents",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonMenuButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const calendarStore = useCalendarStore();
    const {showError} = useErrorBox();

    const categoryId: Ref<string|undefined> = ref(undefined);
    
    const eventTemplates: Ref<Array<EventDescription>> = ref([]);
    const organization: Ref<OrganizationInfo> = ref(store.state.organization);

    const category = ref<EventCategoryViewModel|undefined>(undefined);
    const momentjs: any = moment;
    moment.locale("fi");


    onMounted(async() => {
      try {
      categoryId.value = router.currentRoute.value.params["id"] as string;
      category.value = await calendarStore.getCategory(categoryId.value);
      eventTemplates.value = await calendarStore.getEventDescriptions(
        categoryId.value,
        store.getters.organisation.id, 
        undefined);
      } catch (error) {
        showError(error, "Virhe haettaessa tapahtumia");
      }
    });
   
    const registerToEvent = (item: any) => {
        if(item){
            router.push(`/RegisterTo/${item.id}`);
      }
    };

    return {
      router,
      eventTemplates,
      registerToEvent,
      organization,
      momentjs,
      category,
    };
  },
});
