import { EventCategoryViewModel } from "./EventCategoryViewModel";


export class EventSeasonInfo {
    id: string;
    name: string | undefined;
    type: string | undefined;
    status: string | undefined;
    beginTime: Date | undefined;
    endTime: Date | undefined;

    organizationId: string | undefined;

    metadata: { [key: string]: string };

    categoryId: string | undefined;
    category: EventCategoryViewModel | undefined;

    constructor() {
        this.id = "";
        this.name = "";
        this.type = "";
        this.status = "";
        this.metadata = {};
        this.organizationId = "";
    }
}

