
import {
  IonButtons,
  IonContent,
  IonHeader,
  alertController,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonMenuButton,
  IonText,
  IonIcon,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, ref, Ref, watchEffect, } from "vue";
import { useRouter } from "vue-router";
import { useErrorBox } from "@/components/errorBox";
import { addCircleOutline, alertCircleOutline, pencil, filterOutline } from "ionicons/icons";
import { useTicketStore } from "@/store/ticketStore";
import { OrganizationInfo } from "@/models/Organization";
import { ActionTypes, useStore } from "@/store";
import { TicketType } from "@/models/TicketType";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { useCalendarStore } from "@/store/calendarStore";
import { EventTemplateListDto } from "@/models/EventTemplate";
import moment from 'moment-timezone';
import { LocationViewModel } from "@/models/LocationViewModel";

export default defineComponent({
  name: "EventTemplatesListPage",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonMenuButton,
    IonText,
  },
  setup() {
    const router = useRouter();
    const ticketStore = useTicketStore();
    const calendarStore = useCalendarStore();
    const store = useStore();
    const showAll: Ref<boolean> = ref(false);

    const eventTemplates: Ref<Array<EventTemplateListDto>> = ref([]);
    const organizations: Ref<Array<OrganizationInfo>> = ref([]);
    const selectedIds: Ref<Array<string>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);
    const ticketTypes: Ref<Array<TicketType>> = ref([]);
    const locations: Ref<Array<LocationViewModel>> = ref([]);

    const {showError} = useErrorBox();

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }    

    const init = async () => {
      const l = await loading();
      try {

        organizations.value = await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined);
        allCategories.value = await calendarStore.getCategories(store.state.organization.id);
        ticketTypes.value = await ticketStore.loadTicketTypes();
        locations.value = await calendarStore.getLocations(store.state.organization.id);
        eventTemplates.value = await calendarStore.getEventTemplates(store.state.organization.id);
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    };

   
    watchEffect(async () => {
      if (!store.state.organization.id || store.state.organization.id === "") {
        return;
      }
      await init();
    });

    const showDectivateDialog = async () => {
      const alertElement = await alertController
        .create({
          header: 'Piilotetaanko ' + selectedIds.value.length + ' korttityyppiä?',
          message: 'Korttityypit eivät näy enään kassalla tai asiakkaille.',
          buttons: [
            { text:'Piilota', cssClass:'danger', role:'deactivate' },
            { text:'Peru', cssClass:'secondary', role:'cancel' }],
          });

      await alertElement.present();
      const {role} = await alertElement.onDidDismiss();
      if(role !== "deactivate") return;

      const l = await loading();      
      try {
        for (const id of selectedIds.value) { await ticketStore.deactivateTicketType(id); }
        selectedIds.value = [];
        ticketStore.organisationChanged();
        await ticketStore.loadTicketTypes();
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

    const showActivateDialog = async () => {
      const alert = await alertController
        .create({
          header: 'Aktivoidaako ' + selectedIds.value.length + ' korttityyppiä?',
          message: 'Korttityypit ilmestyvät kassalle.',
          buttons: [
            { text:'Aktivoi', cssClass:'danger', role:'activate' },
            { text:'Peru', cssClass:'secondary', role:'cancel' }],
          });

      await alert.present();
      const {role} = await alert.onDidDismiss();
      if(role !== "activate") return;

      const l = await loading();      
      try {
        for (const id of selectedIds.value) { await ticketStore.activateTicketType(id); }
        selectedIds.value = [];
        ticketStore.organisationChanged();
        await ticketStore.loadTicketTypes();
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

    const allFiltered = computed(() => {
      return eventTemplates.value;
    });

    const select = (ticketType: EventTemplateListDto) => {
      if(!ticketType || !ticketType.templateId) return;

      const index = selectedIds.value.findIndex(i=>i === ticketType.templateId);
      if(index > -1)
        selectedIds.value.splice(index,1);
      else
        selectedIds.value.push(ticketType.templateId);
    };


    return {
      router,
      allFiltered, showAll, organizations,selectedIds, select, allCategories,
      showDectivateDialog, showActivateDialog,
      pencil,filterOutline,addCircleOutline, alertCircleOutline,
      momentjs,ticketTypes,locations,
    };
  },
});
