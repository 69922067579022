
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonMenuButton,
  IonText,
  IonIcon,
  loadingController,
} from "@ionic/vue";
import { defineComponent, ref, Ref, watchEffect, } from "vue";
import { useRouter } from "vue-router";
import { useErrorBox } from "@/components/errorBox";
import { addCircleOutline, alertCircleOutline, pencil, filterOutline } from "ionicons/icons";
import { useTicketStore } from "@/store/ticketStore";
import { OrganizationInfo } from "@/models/Organization";
import { ActionTypes, useStore } from "@/store";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { useCalendarStore } from "@/store/calendarStore";
import { DynamicScroller, DynamicScrollerItem} from 'vue-virtual-scroller';
import { EventSeasonInfo } from "@/models/EventSeasonInfo";

export default defineComponent({
  name: "EventSeasonListPage",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonMenuButton,
    IonText,
    DynamicScroller, DynamicScrollerItem,
  },
  setup() {
    const router = useRouter();
    const ticketStore = useTicketStore();
    const calendarStore = useCalendarStore();
    const store = useStore();

    const organizations: Ref<Array<OrganizationInfo>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);
    const eventSeasons: Ref<Array<EventSeasonInfo>> = ref([]);
    const selectedIds: Ref<Array<string>> = ref([]);

    const {showError} = useErrorBox();
    const formatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 });

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }    

    const init = async () => {
      const l = await loading();
      try {
        organizations.value = await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined);
        allCategories.value = await calendarStore.getCategories(store.state.organization.id);
        eventSeasons.value = await ticketStore.getEventSeasons(store.state.organization.id);
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    };

   
    watchEffect(async () => {
      if (!store.state.organization.id || store.state.organization.id === "") {
        return;
      }
      await init();
    });

    const select = (ticketType: EventSeasonInfo) => {
      const index = selectedIds.value.findIndex(i=>i === ticketType.id);
      if(index > -1)
        selectedIds.value.splice(index,1);
      else
        selectedIds.value.push(ticketType.id);
    };

    const refresh = async (viewStartIndex:number, viewEndIndex:number, visibleStartIndex:number, visibleEndIndex:number) => {
  // console.log('refresh',viewStartIndex, viewEndIndex, visibleStartIndex, visibleEndIndex);

  // if(visibleEndIndex >= messages.value.length - 1) {
    // await onLazyLoad(visibleEndIndex);
  // }
};

    return {
      router,
      organizations, selectedIds, select, allCategories,
      pencil,filterOutline,addCircleOutline, alertCircleOutline,
      formatter, refresh,eventSeasons,
    };
  },
});
