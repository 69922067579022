export class TicketType 
{
    id: string;
    name: string;
    description: string| undefined;
    price: number| undefined;
    vatGroup: string | undefined;
    validityDays: number | undefined;
    validityTimes: number | undefined;
    validityBegins: Date | string | undefined;
    validityEnds: Date | string | undefined;
    pricePeriod: string | undefined;
    accountId: string | undefined;
    isAvailable: boolean | undefined;
    isWebSale: boolean;
    dateOfBirthRequired: boolean | undefined;
    categories: Array<string>;
    discounts: Array<string>;
    dueDate: Date | string | undefined;
    discountGroupId: string | undefined;
    discountGroups: Array<string>;
    organizations: Array<string>;
    accessProfiles: Array<string>;
    minMonths: number | undefined;
    terminateMonths: number | undefined;

    constructor() {
        this.id = "";
        this.name = "";
        this.isWebSale = false;
        this.discountGroups = [];
        this.discounts = [];
        this.categories = [];
        this.organizations = [];  
        this.accessProfiles = [];      
    }
}